<template>
    <button class="toggle" @click.prevent="callApi" :class="{active: currentState}">
        <i class=material-icons>{{ currentIcon }}</i> {{ currentState ? activeText : inactiveText }}
    </button>
</template>
<script>
/* global $ */
export default {
  name: 'ToggleButton',
  data () {
    return {
      currentState: this.state,
    }
  },
  props: {
    api: String,
    state: Boolean,
    icon: String,
    activeIcon: String,
    inactiveIcon: String,
    activeText: String,
    inactiveText: String
  },
  methods: {
    callApi () {
      $.post(this.api, {
          state: !this.currentState
      })
        .done(response => {
          this.currentState = response.new_state
        })
    }
  },
  computed: {
    currentIcon () {
      if (this.icon) {
        return this.icon + (this.currentState ? '_on' : '_off')
      } else {
        return this.currentState ? this.activeIcon : this.inactiveIcon
      }
    }
  }
}
</script>
