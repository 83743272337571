/* global $, MP */

export default {
  name: 'CommentForm',
  data () {
    return {
      comment: '',
      posting: false
    }
  },
  props: {
    inReplyTo: Number
  },
  methods: {
    submit () {
      if (!this.posting) {
        this.posting = true
        $.post(this.action, {
          comment: this.comment,
          in_reply_to: this.inReplyTo,
        })
          .done(response => {
            this.handleResponse(response)
          })
          .always(() => {
            this.posting = false
          })
      }
    },
    handleResponse (response) {
      if (response.status == 'SUCCESS') {
        this.$root.$emit('new-comment', response.object, response.set_follow)
        this.comment = ''
      } else if (response.status == 'WARN') {
        const popup = new MP.Popup(response.advice_title, response.advice_url, content => {
          popup.takeover()
          content.find('.cancel').click(event => {
            event.preventDefault()
            popup.close()
          })
          content.find('.submit').click(event => {
            event.preventDefault()
            $.post(this.action, {
              comment: this.comment,
              in_reply_to: this.inReplyTo,
              override: true
            })
              .done(response => {
                this.handleResponse(response)
              })
            popup.close()
          })
        })
      }
    }
  },
  computed: {
    action () {
      let parent = this.$parent
      while (!parent.postApi) {
        parent = parent.$parent
      }
      return parent.postApi
    }
  }
}
