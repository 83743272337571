/* global $ */

import Comment from './Comment.vue'
import CommentForm from './CommentForm.vue'
import ProfileImage from './ProfileImage.vue'
import ToggleLink from './ToggleLink.vue'

export default {
  name: 'Comment',
  components: {
    Comment,
    CommentForm,
    ProfileImage,
    ToggleLink,
  },
  data () {
    return {
      replyOpen: false
    }
  },
  props: {
    data: Object,
    replies: Array,
    isClosed: Boolean
  },
  computed: {
    isModerator () {
      return this.$parent.isModerator
    },
    moderationApi () {
      return '/diskussion/_comment/' + this.data.id + '/moderation/' + (this.data.hidden ? 'off' : 'on') +'/'
    },
    classes () {
      return {
        'is-hidden': this.data.hidden,
        'is-mine': this.data.edit_url,
        'is-new': this.data.is_new,
        'reply-to': this.replyOpen,
        someClass: this.isModerator,
        'is-moderator': this.data.user.staff_role // User means comment author
      }
    }
  },
  methods: {
    replyClick () {
      var commentable = this.data.in_reply_to ? this.$parent : this
      this.$root.$emit('open-reply', commentable)
    },
  },
  mounted () {
    this.$root.$on('open-reply', component => {
      this.replyOpen = component === this
    })
    this.$on('toggle-update', (child, updateStatus) => {
      this.data.likes = updateStatus
    })
  },
  updated () {
    if (this.replyOpen) {
      $(this.$el).find('textarea').focus()
    }
  }
}
