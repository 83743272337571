/* global $, navPlugins */
import Comment from './Comment.vue'
import CommentForm from './CommentForm.vue'
import ToggleButton from './ToggleButton.vue'
//import $ from 'jquery'

let updatePluginId

export default {
  name:'Discuss',
  components: {
    Comment,
    CommentForm,
    ToggleButton,
  },
  data () {
    return {
      last_update: null,
      comments: [],
      permissions: {},
      loading: true
    }
  },
  props: {
    object: String,
    api: String,
    postApi: String,
    followApi: String,
    notificationName: String,
    isModerator: Boolean,
    following: Boolean,
    isClosed: Boolean
  },
  methods: {
    loadFromApi (initial = false) {
      var data = this.last_update ? { last_update: this.last_update } : {}
      $.get(this.api, data)
        .done(response => {
          if (this.last_update !== null) {
            // Check for preexisting comment id
            const newComments = response.comments.filter(comment => !this.comments.find(c => c.id === comment.id))
            if (newComments.length > 0) {
              // Mutate instead of replacing
              this.comments.push.apply(this.comments, newComments)
            }
          } else {
            this.comments = response.comments
            this.loading = false
          }
          this.last_update = response.last_update
          this.permissions = response.permissions
          if (navPlugins && initial) {
            this.$nextTick(() => {
              navPlugins.call('contentInserted', this.$el)
            })
          }
        })
    },
    repliesTo (id) {
      return this.comments.filter(comment => comment.in_reply_to === id)
    }
  },
  created () {
    this.loadFromApi(true)
    this.$root.$on('new-comment', (comment, setFollow) => {
      if (!this.comments.find(c => c.id === comment.id)) {
        this.comments.push(comment)
      }
      if (setFollow) {
        this.$refs.followButton.currentState = true
      }
    })
    if (navPlugins) {
      updatePluginId = navPlugins.register({
        intervalUpdate: () => {
          this.loadFromApi()
        },
        notificationsUpdate: update_name => {
          if (update_name === this.notificationName) {
            this.loadFromApi()
          }
        }
      })
    }
  },
  beforeDestroy () {
    if (updatePluginId) {
      navPlugins.unregister(updatePluginId)
    }
  },
  computed: {
    level0 () {
      return this.comments.filter(comment => comment.in_reply_to === null)
    }
  },
  watch: {
    comments (val, oldVal) {
      for (var i=0; i<val.length; i++) {
        let obj = val[i]
        if (obj.is_new) {
          if (oldVal.length !== 0 || oldVal.indexOf(obj) === -1) {
            $.post(window.location, {
              update_read_timestamp: 1
            })
            break
          }
        }
      }
    }
  }
}
