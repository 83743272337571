<template>
  <img :src="src" :style="style" width="200" height="200" class="profile-image" :title="title" />
</template>
<script>
export default {
  name:'ProfileImage',
  props: {
    src: String,
    title: String
  }
}
</script>
