/* global navPlugins, $ */

import Vue from 'vue'
import Discuss from './components/Discuss.vue'

Vue.config.productionTip = false

$(() => {
  let mainContentVue = null

  function checkMountVue() {
    if (document.querySelector('discuss')) {
      mainContentVue = new Vue({
        el: 'discuss',
        components: {
          Discuss
        },
      })
    }
  }
  checkMountVue()

  if (navPlugins) {
    navPlugins.register({
      pageUpdate: () => {
        if (mainContentVue !== null) {
          mainContentVue.$destroy()
          mainContentVue = null
        }
        checkMountVue()
      }
    })
  }
})
